.learn-popup {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: var(--bg-color);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    overflow-x: hidden;
}

.learn-popup .templ-popup__close {
    display: none;
}

.learn-popup_visible {
    z-index: 1000;
}

.learn-popup_unvisible {
    z-index: 0;
}

.learn-popup__img {
    /* width: 124%;
    max-width: 500px; */
    height: 100vh;
    margin-top: -250px;
    z-index: 1;
    /* filter: blur(100px); */
}

.learn-popup__img_center {
    position: absolute;
    top: 0px;
    max-width: 315px;
}

.learn-popup__img_center-first {
    top: 21px;
    max-width: 376px;
}

.learn-popup__img_center-second {
    top: 36px;
    /* left: 16px; */
    max-width: 301px;
}

.learn-popup__img_center-third {
    top: 20px;
}

.learn-popup__img_center-four {
    top: 0px;
    max-width: 140vw;
    z-index: 2;
}

.learn-popup__templ {
    padding-bottom: 45px;
    box-shadow: none;
    /* background: rgba(40, 28, 65, 0.60); */
}

.learn-popup__coin {
    width: 104px;
    height: 104px;
}

.learn-popup__swiper .swiper-pagination {
    width: 116px;
    height: 10px;
    top: 82% !important;
    left: 0;
    text-align: left;
}

.learn-popup .swiper-pagination-bullet {
    background: #8B8D9B;
    opacity: 0.5;
    width: 10px;
    height: 10px;
}

.learn-popup .swiper-pagination-bullet-active {
    background: #8CC8FF;
    opacity: 1;
    width: 28px;
    border-radius: 600px;
}

.learn-popup .swiper {
    width: 100%;
    max-width: 360px !important;
    height: 200px;
    margin-top: 40px;
}

.learn-popup__swiper .swiper-slide {
    display: grid;
    grid-template-columns: 40px 1fr;
    width: 100%;
    gap: 26px;
    justify-items: start;
}

.learn-popup__swiper__btn {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    display: inline-flex;
    padding: 12px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;
    bottom: 54px;
    right: 26px;
    z-index: 102;

    border-radius: 10px;
    border: 1px solid var(--second-contrast-color);
    color: var(--second-contrast-color);
    min-width: 102px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.learn-popup__swiper__btn_end {
    background: var(--second-contrast-color);
    color: var(--bg-color);
    transition: all 0.2s ease-in-out;
}

.learn-popup__text {
    color: var(--contrast-color);
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    /* max-width: 180px; */
    user-select: none;
}

.learn-popup__subtext {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 16px;
    user-select: none;
}