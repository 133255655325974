.main__tap-btn__box {
    position: relative;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.main__tap-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 130px;
    height: 130px;

    z-index: 5;
    border-radius: 600px;

    border: 0.1px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(180deg, var(--clicker-gradient-first) 0%, var(--clicker-gradient-second) 51.14%, var(--clicker-gradient-third) 100%);
    box-shadow: 0px 0.6px 0px 0px rgba(255, 255, 255, 0.16);
    transition: all ease-out 0.2s;

}

.main__tap-btn_active-orange {
    transition: all ease-out 0.2s;
    /* transition: all ease-in-out 0.3s; */
    background: linear-gradient(180deg, #F6B700 0%, #FE9B28 43%, #F6B700 100%);
    box-shadow: 0px 1.2px 0px 0px #FFD260, 0px 0px 24px 0px rgba(255, 190, 115, 0.45);
}

.main__tap-btn_active-green {
    transition: all ease-out 0.2s;
    /* transition: all ease-in-out 0.3s; */
    background: linear-gradient(180deg, #3DE283 0%, #52FC6D 53.5%, #3DE283 100%);
    box-shadow: 0px 1.2px 0px 0px #7DFF7B, 0px 0px 24px 0px rgba(115, 255, 146, 0.45);
}

.main__img_btn-circle {
    border-radius: 102px;
    border: 0.4px solid var(--text-color);
    background: lightgray 50% / cover no-repeat;
    max-width: 102px;
    position: absolute;
    /* left: 0; */
    /* top: 0; */
    z-index: 9;
}

.main__img_btn-cue {
    position: absolute;
    z-index: 10;
}

.main__img_btn-box {
    display: flex;
    
}

.main__img_btn-pause-box {
    position: absolute;
    display: flex;
    gap: 4px;
}

.main__img_btn-pause {
    border-radius: 1.6px;
    background: linear-gradient(180deg, #303040 0%, #494951 100%);
    box-shadow: 0px 0.4px 0px 0px rgba(255, 255, 255, 0.70);
    width: 6px;
    height: 16px;
}

.main__img_btn-border {
    max-width: 111.429px;
    z-index: 8;
}

.main__img_btn-bg-lighting {
    /* background: var(--clicker-full-circle); */
    mix-blend-mode: soft-light;
    width: 112px;
    height: 112px;
    position: absolute;
    z-index: 7;
    border-radius: 50%;

}

.main__img_btn-bg-lighting_active-orange {

    background: linear-gradient(0deg, rgba(255, 165, 31, 0.00) 23.75%, rgba(255, 139, 31, 0.50) 100%);
}

.main__img_btn-bg-lighting_active-green {

    background: linear-gradient(0deg, rgba(40, 135, 70, 0.00) 23.75%, rgba(70, 237, 123, 0.50) 100%);
}

.main__img_btn-bg-color {
    width: 116px;
    height: 116px;
    background: var(--clicker-full-circle);
    position: absolute;
    z-index: 6;
    border-radius: 50%;

}

.main__img_btn-bg-orange_active {
    background: #B4711B;

}

.main__img_btn-bg-green_active {
    background: #306748;

}

.main__img_btn-bg_active {
    width: 140px;
    height: 140px;
    background: linear-gradient(180deg, var(--clicker-bg-first) 0%, var(--clicker-bg-second) 100%);
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    z-index: 4;

}

.main__img_btn-bg {
    background: linear-gradient(180deg, var(--clicker-bg-first) 0%, var(--clicker-bg-second) 100%);
    opacity: 0.5;

    width: 140px;
    height: 140px;
    border-radius: 50%;
    position: absolute;
    z-index: 4;

}