.friends {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    position: relative;
    overflow-x: hidden;
    z-index: 2;
}

.friends__title {
    color: var(--contrast-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    user-select: none;
}

.friends__title_white {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    user-select: none;
}

.friends__desc {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    user-select: none;
}

.friends__plash {
    width: 100%;
    border-radius: 16px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    padding: 12px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 72px 1fr;
    column-gap: 17px;
    /* gap: 12px;
    align-items: center;
    justify-content: flex-start; */
    margin-top: 18px;
    user-select: none;
    overflow: hidden;
    min-height: 104px;
    align-items: center;
}

.friends__plash__circle {
    position: absolute;
    width: 118px;
    height: 118px;
    background: #EE45BF;
    opacity: 0.7;
    /* mix-blend-mode: plus-lighter; */
    filter: blur(70px);
    left: 22px;
    top: 27px;
    z-index: 2;
}

.friends__plash__circle_purple {
    background: #AE45EE;
    opacity: 0.7;
    /* mix-blend-mode: plus-lighter; */
    filter: blur(130px);
    width: 154px;
    height: 154px;
    flex-shrink: 0;
    position: absolute;
    top: -64px;
    left: -91px;
    z-index: 0;
}

.friends__plash__img {
    /* width: 83px; */
    /* height: 80px; */
    /* transform: scale(0.5); */
    user-select: none;
    position: absolute;
    top: -284px;
    left: -305px;
    z-index: 1;
    width: 596px;
    height: 604px;
}

.friends__plash__title {
    color: var(--text-color);
    /* text-align: center; */
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    width: 100%;
    user-select: none;
    position: relative;
    z-index: 3;
}

.friends__plash__text-box {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 6px;
    position: relative;
    z-index: 3;
}

.friends__plash__number {
    color: var(--text-color);
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */

    display: flex;
    gap: 4px;
    align-items: center;
    user-select: none;
}

.friends__plash__desc {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 183.333% */
    user-select: none;
}

.friends__plash__coin {
    width: 22px;
    height: 12px;
    user-select: none;
}

.friends__cards-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
    gap: 6px;
    padding-bottom: 250px;
}

.friends__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, var(--bg-color) 63.09%, rgba(29, 18, 52, 0.00) 93.83%);
    right: 0;
    height: 298px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.friends__btn {
    position: fixed;
    z-index: 10;
    max-width: 400px;
    width: 93%;
    height: 64px;
    bottom: 125px;

    display: grid;
    grid-template-columns: 1fr 64px;
    gap: 12px;
    cursor: pointer;
}

.friends__btn_invite {
    border-radius: 16px;
    background: var(--second-contrast-color);
    display: inline-flex;

    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    cursor: pointer;
}

.friends__btn__icon {
    background: rgba(43, 43, 63, 0.51);
    border-radius: 16px;
    user-select: none;
}

.friends__animated-gradient {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;

    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    border-radius: 44px;

    height: 20px;
    width: 120px;
    border: 1px solid rgba(71, 71, 89, 0.51);
}

.friends__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    text-align: center;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}