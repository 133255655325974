.friends-card {
    border-radius: 16px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    padding: 8px 20px 8px 10px;

    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 10px;
}

.friends-card__img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    object-fit: cover;
}

.friends-card__name {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
}

.friends-card__bonus {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */

    display: flex;
    align-items: center;
    gap: 2px;
    user-select: none;
}

.friends-card__income {
    color: var(--text-color);
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 150% */
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;
}

.friends-card__coin {
    user-select: none;
    width: 18px;
    height: 10px;
}

.friends-card__name-box {
    display: flex;
    gap: 2px;
    flex-direction: column;
    margin-left: 10px;
}

.frineds-card__left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.frineds-card__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.friends-card__btn {
    color: var(--contrast-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */

    border-radius: 44px;
    background: rgba(71, 71, 89, 0.51);
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid rgba(71, 71, 89, 0.51);
    width: 120px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.friends-card__btn_yellow {
    border-color: var(--contrast-color);
    transition: all ease-in-out 0.2s;
}

.friends-card__btn_error {
    background: rgba(255, 255, 255, 0.06);
    color: #FF614C;
    cursor: none;
    border-color: rgba(255, 255, 255, 0.06);
    user-select: none;
    transition: all ease-in-out 0.2s;
}

.friends-card__btn-box {
    position: relative;
    width: 120px;
}

.friends-card_animated-gradient {
    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;
    height: 56px;
}

.friends-card_animated-gradient .frineds-card__left {
    display: none;
}

.friends-card_animated-gradient .frineds-card__right {
    display: none;
}

.friends-card__btn__animated-gradient {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;

    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    border-radius: 44px;

    height: 20px;
    width: 120px;
    border: 1px solid rgba(71, 71, 89, 0.51);

    /* display: none; */
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@media (max-width:400px) {
    .frineds-card__left {
        justify-content: flex-start;
        overflow: hidden;
    }
}

@media (max-width:350px) {
    .frineds-card__right {
        gap: 5px;
    }
}