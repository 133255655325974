.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color);
  padding: 0 12px;
  box-sizing: border-box;
}

.app_scrollable {
  padding-bottom: 115px;
}