.selector {
  height: 58px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 6px;

  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  padding: 0 23px;
  transition: all 0.2s ease-in-out;

  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--second-bg-gray);
  backdrop-filter: blur(2px);
  padding: 6px;

  position: relative;
}

.selector__input {
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  transition: all 0.2s ease-in-out;

  color: var(--text-color);
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 122.222% */
  letter-spacing: 0.72px;
  margin-top: 10px;
  user-select: none;
}

.selector__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #8B8D9B;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 116.667% */
  transition: all 0.2s ease-in-out;
  height: 100%;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.selector__btn_active {
  border-radius: 10px;
  background: var(--second-bg-gray);
  backdrop-filter: blur(2px);

  color: var(--contrast-color);
  transition: all 0.2s ease-in-out;
}