.popup-business {
    z-index: 1100 !important;
}

.popup-business__img {
    border-radius: 12px;
    width: 68px;
    height: 68px;
    object-fit: cover;
    user-select: none;
}

.popup-business__text {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 109.091% */
    letter-spacing: 1.76px;
    text-transform: uppercase;
    margin-top: 16px;
    user-select: none;
}

.popup-business__desc {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 8px;
    user-select: none;
}

.popup-business__price {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;
    user-select: none;
}

.popup-business__info {
    display: flex;

    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */

    width: 90%;
    justify-content: space-between;
    margin-top: 20px;
    user-select: none;
}

.popup-business__coin_grey {
    width: 18px;
    height: 10px;
    user-select: none;
}

.popup-business__income-difference {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 4px;
    user-select: none;
}

.popup-business__income_now {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;
}

.popup-business__income_future {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    display: flex;
    align-items: center;
    gap: 4px;
    user-select: none;
}

.popup-business__income_future svg {
    width: 18px;
    height: 10px;
}