@import url(./assets/fonts/NunitoSans/stylesheet.css);
@import url(./assets/fonts/Mayak/stylesheet.css);

body {
  margin: 0;
  font-family: 'Nunito Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
  user-select: none;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Nunito Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.button:hover {
  background-color: none;
  background: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

html[data-theme="light"] {
  --text-color: #1D1234;
  --dark-text-color: #170936;
  --light-text-color: #D5BEFF;
  --bg-color: #F4F4FD;
  --second-bg-gray: #fff;

  --contrast-color: #50FA70;
  --second-contrast-color: #27CE46;

  --header-btn-course: #F4F4FD;
  --header-btn-course-border: #27CE46;

  --header-level-bg: #DCD6E9;
  --header-border: #DCD6E9;
  --energy-line: #C9C9DC;
  --clicker-full-circle: #707080;

  --clicker-gradient-first: rgba(255, 255, 255, 0.51);
  --clicker-gradient-second: rgba(172, 179, 214, 0.18);
  --clicker-gradient-third: rgba(201, 205, 224, 0.32);
  --clicker-bg-first: #A0A0C4;
  --clicker-bg-second: rgba(255, 255, 255, 0.00);

  --popup-bg: #F4F4FD;
  --popup-shadow: rgba(209, 209, 218, 0.69);
  --popup-btn-error-bg: rgba(78, 81, 139, 0.50);

  --nav-bg: rgba(255, 255, 255, 1);
  --nav-bg-active: rgba(78, 81, 139, 0.50);

  --business-card-line: #9C93AF;
}

html[data-theme="dark"] {
  --text-color: #FFFFFF;
  --dark-text-color: #170936;
  --light-text-color: #D5BEFF;
  --bg-color: #1D1234;
  --second-bg-gray: #281C41;

  --contrast-color: #50FA70;
  --second-contrast-color: #27CE46;

  --header-btn-course: #372D4C;
  --header-btn-course-border: #372D4C;

  --header-level-bg: #3B3B52;
  --header-border: #2B2B3F;
  --energy-line: #45395C;
  --clicker-full-circle: #1D1234;

  --clicker-gradient-first: rgba(255, 255, 255, 0.05);
  --clicker-gradient-second: rgba(255, 255, 255, 0.18);
  --clicker-gradient-third: rgba(214, 214, 214, 0.00);
  --clicker-bg-first: #040209;
  --clicker-bg-second: rgba(29, 18, 52, 0.00);

  --popup-bg: rgba(40, 28, 65, 0.60);
  --popup-shadow: rgba(30, 30, 45, 0.69);
  --popup-btn-error-bg: rgba(255, 255, 255, 0.06);

  --nav-bg: rgba(63, 63, 89, 0.60);
  --nav-bg-active: rgba(255, 255, 255, 0.05);

  --business-card-line: #3E3E55;
}

::-webkit-scrollbar {
  background-color: transparent;
}