.profile__info {
    border-radius: 16px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    padding: 20px 30px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
    min-height: 276px;
}

.profile__info__text {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    user-select: none;
}

.profile__info__text_small {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 140% */
    user-select: none;
}

.profile__info-desc {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 6px;
    width: 100%;

    user-select: none;
}

.profile__info__btn_save {
    border-radius: 16px;
    background: var(--second-contrast-color);

    color: #fff;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    width: 100%;
    padding: 20px 0px;
    margin-top: 12px;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.profile__info__btn_edit {
    border-radius: 16px;
    border: 1px solid var(--second-contrast-color);
    padding: 21px 0 21px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: var(--second-contrast-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 110% */
    width: 100%;
    margin-top: 8px;
}

.profile__info .animated-gradient {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;

    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    /* margin-top: 8px; */
    border-radius: 16px;
    height: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}