.popup-task__img {
    border-radius: 12px;
    object-fit: cover;
    width: 86px;
    height: 68px;
}

.popup-task__text {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 109.091% */
    margin-top: 16px;

    user-select: none;
}

.popup-task__desc {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 8px;

    user-select: none;
}

.popup-task__price {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;

    user-select: none;
}

.popup-task__btn_error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    /* background: var(--popup-btn-error-bg); */
    width: 100%;

    padding: 12px 0px 12px 0px;
    gap: 6px;
    margin-top: 32px;
    border: 1px solid #FF614C;
    color: #FF614C;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */

    user-select: none;
}

.popup__bg {
    background: transparent;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: -106px;
    z-index: 999;
}

.popup-level-up__btn_already-buy {
    cursor: none;
    user-select: none;
    transition: all ease-in-out 0.5s;
}