.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 400px;
    padding-bottom: 10px;
    margin-top: 10px;
    z-index: 98;
}

.header__box_avatar-and-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.header__btn_course {
    border-radius: 60px;
    background: var(--header-btn-course);
    display: inline-flex;
    padding: 6px 22px;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--header-btn-course-border);
    color: #50FA70;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 120% */
}

.header .popup-info {
    z-index: 101 !important;
}

.header__avatar-box {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-self: center;
}

.header__avatar-box__img {
    border-radius: 6px;
    background-blend-mode: exclusion, normal;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.header__avatar-box__text {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 116.667% */
    user-select: none;
}

.header__info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 12px;
    border: 1px solid var(--header-border);
    backdrop-filter: blur(2px);
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    gap: 5px;
}

.header__info-box__coin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    max-width: 150px;
    width: 100%;
    user-select: none;
}

.header__info-box__coin__text-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header__info-box__coin__text_white {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */
    user-select: none;
}

.header__info-box__coin__text_grey {
    color: #777786;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    user-select: none;
}

.header__info-box__rang {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header__info-box__coin__text_white-bold {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    gap: 4px;
    align-items: center;
    /* 133.333% */
    user-select: none;
}

.header__info-box__coin__text_grey-small {
    color: #8B8D9B;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    /* 125% */
    user-select: none;
}

.header__info-box__hour {
    display: flex;
    gap: 11px;
    position: relative;
    z-index: 100;
}

.header__info-box__hour__img {
    max-width: 30px;
}

.header__info-box__hour__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    position: relative;
    z-index: 100;
}

.loading__progress-box {
    width: 150px;
    height: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: var(--header-level-bg);
    user-select: none;
}

.loading__progress {
    transition: all 0.1s ease-in-out;
    height: 100%;
    border-radius: 16px;
    background-color: var(--contrast-color);
}

.header__info-box__coin-icon {
    width: 16px;
    height: 16px;
}

@media (max-width:360px) {
    .header__info-box__coin__text_grey-small {
        font-size: 6px;
    }

    .header__info-box__coin {
        width: 100px;
    }

    .loading__progress-box {
        width: 100px;
    }
}