.rating {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
    padding: 0px 12px 30px 12px;
    box-sizing: border-box;
}

.rating__title {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    width: 100%;
    z-index: 0;
    position: sticky;
    top: -1px;
    background-color: var(--bg-color);
    /* padding-top: 30px; */
    padding: 30px 12px 0 12px;
}

.rating__header-table {
    display: grid;
    grid-template-columns: 37px 2fr 1fr;
    width: 95%;
    /* justify-content: space-between; */
    padding-top: 38px;
    border-bottom: solid var(--second-bg-gray) 1px;
    padding-bottom: 12px;
    position: sticky;
    top: 21px;
    background-color: var(--bg-color);
    padding: 38px 12px 12px;
}

.rating__header-table__text {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 125% */
}

.rating__header-table__text:last-of-type {
    text-align: end;
}

.rating__cards-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
}

.rating-card-box {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    
    display: flex;
    justify-content: center;
    max-width: 500px;
    height: 165px;
    /* border-top: 1px solid var(--contrast-color); */
    background: var(--bg-color);
    /* box-shadow: 0px -1px 0px 0px #2B2B3F; */
}

.rating-card_your {
    height: fit-content;
    display: flex;
    /* align-items: flex-start !important; */
    width: 93% !important;
    /* max-width: 360px; */
    display: grid;
    grid-template-columns: 38px 42px 1.5fr 1fr 1fr;

    padding: 10px 0;
    align-items: center;
}

.rating-card__name_yellow {
    color: var(--contrast-color) !important;
}

.rating-box {
    background: var(--bg-color) !important;
    height: 100vh !important;
    box-shadow: none !important;
    justify-content: start !important;
    z-index: 99 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    max-width: 500px;
}

.rating__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 103vw;
    background-color: var(--contrast-color);
    height: 1px;
    right: 0;
}