.auth-input {
  height: 58px;
  display: grid;
  /* grid-template-columns: max-content 1fr; */
  align-items: center;
  gap: 16px;

  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  padding: 0 23px;
  transition: all 0.2s ease-in-out;

  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--second-bg-gray);
  backdrop-filter: blur(2px);
  padding: 10px 0px 8px 16px;

  position: relative;
}

.auth-input_inactive {
  border: 4px solid #4200FF;
  transition: all 0.2s linear;
}

.auth-input_valid {
  border: 4px solid var(--partners-second-card-border);
  transition: all 0.2s linear;
}

.auth-input_not-valid {
  border: 4px solid #FF0000 !important;
  transition: all 0.2s linear;
}

.auth-input__icon {
  max-width: 35px;
  height: 39px;
}

.auth-input__input {
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  transition: all 0.2s ease-in-out;

  color: var(--text-color);
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 122.222% */
  letter-spacing: 0.72px;
  margin-top: 10px;
}

.label {
  color: #8B8D9B;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 19px;
  left: 16px;
  transition: all 0.5s ease-in-out;
}

.label_focus {
  top: 5px;

  color: #8B8D9B;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  transition: all 0.5s ease-in-out;
}

.react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero {
  display: none;
}

.label_focus .react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero {
  display: block;
}