.mini-preloader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    background-color: var(--second-bg-gray);
    border-radius: 50%;

}

.mini-preloader__container {
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mini-preloader__loader {
    width: 16px;
    height: 16px;
    animation: rotate 0.5s infinite linear;
}

.mini-preloader__loader-color {
    stopColor: var(--contrast-color);
}

.mini-preloader__loader-color_link {
    stopColor:  var(--contrast-color);
}

.mini-preloader__loader_big {
    width: 40px;
    height: 40px;
}

.mini-preloader__container_big{
    width: 40px;
    height: 40px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}