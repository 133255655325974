.profile {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    padding-bottom: 150px;
    margin-top: 18px;
    z-index: 2;
}

.profile__img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    user-select: none;
    object-fit: cover;
}

.profile__coin-box {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    gap: 8px;
    user-select: none;
}

.profile__coin {
    width: 20px;
    height: 20px;
}

.profile__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.profile__level {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 166.667% */
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.profile__level-number {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    user-select: none;
}

.profile__img-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
}

.profile__name {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 116.667% */
    margin-top: 8px;

    user-select: none;
}

.profile_id {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    user-select: none;
}

.profile__btn {
    color: var(--contrast-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */

    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 44px;
    background: rgba(43, 43, 63, 0.51);

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 200;

    cursor: pointer;
}

.profile__nav-block {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    justify-content: space-around;
    border-radius: 12px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    padding: 2px;

    position: sticky;
    top: 12px;
    z-index: 98;

    box-shadow: 10px 5px 8px var(--bg-color), -5px -4px 8px 0px var(--bg-color);
    backdrop-filter: blur(5px);

    margin-top: 12px;
}

.profile__nav {
    padding: 14px 18px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    transition: all 0.5s ease-in-out;
    max-height: 46px;
    user-select: none;
}

.profile__nav_active {
    background: var(--bg-color);
    /* font-size: 12px; */
    font-weight: 700;
    transition: all 0.5s ease-in-out;
    user-select: none;
}

.profile__title {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-top: 22px;
    width: 100%;
    user-select: none;
}

.profile__title_empty {
    margin-top: 50px;
    user-select: none;
}


.profile__grid {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 6px;
}

.profile__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, var(--bg-color) 63.09%, rgba(29, 18, 52, 0.00) 93.83%);
    right: 0;
    height: 162px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}


.profile__btn_upload {
    color: var(--neutral-active);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 0;
    cursor: pointer;
}

.profile__wrapper-button {
    position: absolute;
    left: 0px;
    top: -6px;
    opacity: 0;
    z-index: 6;
    cursor: pointer !important;
    width: 110%;
    height: 110%;
    padding: 0;
}

.profile__wrapper-button_deletable {
    position: absolute;
    left: -8px;
    top: 7px;
    opacity: 0;
    z-index: 6;
    cursor: pointer !important;
    width: 100%;
    height: 88%;
    padding: 0;
    transform: rotate(45deg);
}

input#file-upload-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.profile__btn_upload input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    font-family: 'Gorizont', sans-serif;
    -webkit-box-shadow: 0 0 0 500px var(--website-secondary-background) inset !important;
    /* можно изменить на любой вариант цвета */
    -webkit-text-fill-color: var(--neutral-active) !important;
    background-color: var(--website-secondary-background) !important;
    background-clip: content-box !important;
    position: relative;
    z-index: 0;
}

.profile__btn__add-krest {
    position: absolute;
    top: -4px;
    right: -4px;
    background: #5B536B;
    border-radius: 50%;
    z-index: 3;
}

.profile__btn__delete {
    position: absolute;
    top: -4px;
    right: -4px;
    background: #5B536B;
    border-radius: 50%;
    z-index: 3;
}