.nav-block {
    position: fixed;
    bottom: 34px;
    width: 93%;
    left: auto;
    right: auto;
    box-sizing: border-box;
    z-index: 100;
    max-width: calc(400px - 0px);
}

.nav-block__box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 16px;
    background: var(--nav-bg);
    backdrop-filter: blur(7px);
    padding: 2px;
    gap: 3px;
    justify-content: space-between;
    align-items: center;
    height: 76px;
}

.nav-block__title {
    color: #5F5F86;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */
    user-select: none;
}

.nav-block__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 11px;
    border-radius: 16px;
    /* background: rgba(63, 63, 89, 0.60);
    backdrop-filter: blur(7px); */
    transition: all ease-in-out 0.1s;
    background-color: rgba(255, 255, 255, 0);
}

.nav-block__link_active {
    gap: 11px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 14px;
    background-color: var(--nav-bg-active);
    transition: all ease-in-out 0.1s;

}

.nav-block__link_active .nav-block__icon_active {
    stroke: var(--contrast-color);
    transition: all ease-in-out 0.1s;
}

.nav-block__link_active .nav-block__icon_active-fill {
    fill: var(--contrast-color);
    transition: all ease-in-out 0.1s;
}

.nav-block__link_active .nav-block__icon_active_second {
    stroke: var(--text-color);
    transition: all ease-in-out 0.1s;
}

.nav-block__link_active .nav-block__icon_active_second-fill {
    fill: var(--text-color);
    transition: all ease-in-out 0.1s;
}

.nav-block__link_active .nav-block__title {
    color: #fff;
}

.nav-block__red-circle {
    position: absolute;
    z-index: 101;
    right: 16px;
    top: 12px;
    border-radius: 50%;
    background-color: #FF002E;
    width: 6px;
    height: 6px;
}