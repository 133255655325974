.rewards-box {
    background: var(--bg-color) !important;
    height: 100vh !important;
    box-shadow: none !important;
    justify-content: start !important;
    z-index: 101 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.rewards {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    position: relative;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    padding-bottom: 100px;
    padding: 0px 12px 120px 12px !important;
    box-sizing: border-box;
    background-color: var(--bg-color);

}

.rewards__title {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    text-transform: uppercase;
    width: 100%;
    z-index: 0;
    position: sticky;
    padding-top: 30px;
    background-color: var(--bg-color);
    top: -1px;
    z-index: 2;
    padding-bottom: 15px;
}

.rewards__cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    margin-top: 15px;
    width: 100%;
}

.rewards__cards-grid .main-task {
    min-height: 212px;
    width: 100%;
}

.rewards__cards-grid .main-task__img {
    max-height: 212px;
    height: 100%;
    height: 140px;
}

.rewards__cards-grid .main-task__value {
    width: 100%;
    text-align: left;
    overflow: initial;
}

.rewards__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, var(--bg-color) 63.09%, rgba(29, 18, 52, 0.00) 93.83%);
    right: 0;
    height: 162px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}