.loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: var(--bg-color); */
    /* justify-content: flex-end; */

    /* position: relative; */
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 0 0;
    justify-content: end;
    align-items: center;
    
    user-select: none;
}

.loading__title {
    color: var(--text-color);
    text-align: center;

    text-shadow: 0px 3px 0px var(--contrast-color);
    -webkit-text-strokeWidth: 2;
    -webkit-text-stroke-color: #FFDB95;
    font-family: Mayak;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 202px; 202% */
    letter-spacing: 2px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    gap: 14px;
    position: relative;
    z-index: 1;
    margin-top: 20px;
}

.loading__title_second {
    margin-top: -60px;
}

.loading__bg {
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 500px;
    top: 0;
    /* max-height: 90vh; */
    object-fit: cover;
    z-index: 0;
    /* background: linear-gradient(180deg, var(--bg-color) 0%, rgba(30, 30, 45, 0.00) 19.37%, rgba(30, 30, 45, 0.00) 41.11%, var(--bg-color) 79.05%), url(<path-to-image>) lightgray 0px -92.934px / 100% 113.548% no-repeat; */
    mix-blend-mode: lighten;
}

.loading__star-bg {
    position: absolute;
    top: 0;
    /* left: -60px; */
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loading__line {
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
    margin-bottom: -50px;
    z-index: 0;
}

.loading__line__box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* margin: 0 0 -200px 0; */
    overflow: hidden;
}

.loading__load-text {
    color: var(--text-color);
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */
    margin-top: 5px;
}

.loading__terms {
    color: #ABADBF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    margin-top: 30px;
}

.loading__terms-promo-link {
    color: var(--contrast-color);
    opacity: 1;
}

.loading__progress-box {
    /* width: 217px;
    height: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: #2A2C3C;
    margin: 16px 0 0; */
}

.loading__svg {
    transform: scale(1.3);
}

.loading__progress {
    transition: all 0.1s ease-in-out;
    height: 100%;
    border-radius: 16px;
    background-color: var(--contrast-color);
}

.loading__perent {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin: 12px 0 0;
    color: var(--text-color);
}

.loading__blue {
    left: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 214px;
    background: linear-gradient(180deg, rgba(30, 30, 45, 0.00) 2.68%, var(--bg-color) 57.3%);

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading__social-box {
    opacity: 0.4;
    display: flex;
    gap: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
    min-height: 126px;
}

circle,
rect,
line {
    stroke-width: 3px;
    stroke-linecap: round;
    fill: transparent;
}

.loading__error {
    display: flex;
    align-items: center;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(3.5px);
    padding: 8px 14px;
    gap: 6px;
}

.loading__error-text {
    color: #FF614C;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}