.templ-popup {
    border-radius: 30px 30px 0 0;
    background: var(--popup-bg);
    box-shadow: 0px -0.6px 0px 0px var(--contrast-color), 0px -20px 44px 0px var(--popup-shadow);
    backdrop-filter: blur(5px);

    width: 100vw;
    max-width: 400px;
    height: fit-content;
    padding: 20px 20px 90px 20px;

    position: fixed;
    bottom: 0;
    left: auto;

    box-sizing: border-box;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
}

.templ-popup__close {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 100;
}