.profile-card {
    border-radius: 8px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 6px 12px 6px;
    box-sizing: border-box;
    gap: 6px;
}

.profile-card__value {
    color: var(--text-color);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
    margin-top: 12px;
    user-select: none;
}

.profile-card__img {
    width: 100%;
    border-radius: 6px;
    /* object-fit: cover; */
    user-select: none;

    /* max-width: 168px; */
    height: 140px;
    object-fit: contain;
    background-color: #35354C;
}

.profile-card_grey {
    filter: grayscale(100%);
}

.profile-card__animated-gradient {
    border-radius: 8px;
    background: var(--second-bg-gray);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 6px 12px 6px;
    box-sizing: border-box;
    gap: 6px;

    /* background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    height: 100%; */
}

.profile-card__img_animated-gradient {
    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    height: 100%;

    border-radius: 6px;
    object-fit: cover;
    user-select: none;

    /* max-width: 168px; */
    height: 140px;
}

.profile-card__value_animated-gradient {
    background: repeating-linear-gradient(to right, var(--bg-color) 0%, var(--second-bg-gray) 50%, var(--bg-color) 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    height: 100%;
    height: 16px;
    border-radius: 8px;
    margin-top: 12px;
}